import bandbild from "./assets/images/Bandbild.jpeg";
import instaLogo from "./assets/images/logo_insta.png";
import letter from "./assets/images/1814108_email_envenlope_letter_mail_icon.png";

import "./App.css";
import React from "react";

function App() {
  return (
    <div className="App">
      <h1>FREE BANDIT</h1>
      <div className="stickyTop">
        <nav>
          <a href="#gigs">Gigs</a>
          <a href="#songs">Songs</a>
          <a href="#contact">Contact</a>
          <a
            href="https://instagram.com/freebanditband"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="logoInsta" src={instaLogo} />
          </a>
        </nav>
      </div>
      <div className="hero">
        <img alt="bandbild" src={bandbild} />
      </div>
      <a className="anchor" id="gigs"></a>
      <h2>2023</h2>
      <div className="gigs">
        <p>21. Jun - Fete de la Musique Potsdam</p>
        <p>Nov - RockSTAM#2</p>
      </div>

      <a className="anchor" id="songs"></a>
      <h2>Songs</h2>
      <iframe
        // style="border-radius:12px"
        title="Songs"
        src="https://open.spotify.com/embed/artist/57gU7hWoItNLnOatP9nG5t?utm_source=generator"
        width="80%"
        height="352"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      ></iframe>
      <a className="anchor" id="contact"></a>
      <h2 id="contact">Contact</h2>
      <div className="contact">
        <a href="mailto:booking@free-bandit.de?subject=booking Free Bandit">
          booking@free-bandit.de
        </a>
        <a href="mailto:booking@free-bandit.de?subject=booking Free Bandit">
          <img src={letter} alt="letter" />
        </a>
      </div>
    </div>
  );
}

export default App;
